import React from "react";
import Cursor from "../components/Cursor";
import Navigation from "../components/Navigation";
import Langages from "../components/competence/Langages";
import Diplome from "../components/competence/Diplome";

const Competence = () => {
  return (
    <div className="back">
      <div className="back_gradient">
        <Cursor />
        <Navigation />
        <div className="container_competence">
          <Langages />
          <Diplome />
        </div>
      </div>
    </div>
  );
};

export default Competence;
