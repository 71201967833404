import { useRef, useEffect } from "react";

const Cursor = () => {
  const dot = useRef(null);

  const endX = useRef(window.innerWidth / 2);
  const endY = useRef(window.innerHeight / 2);

  const mouseMoveEvent = (e) => {
    endX.current = e.pageX;
    endY.current = e.pageY;

    dot.current.style.top = endY.current + "px";
    dot.current.style.left = endX.current + "px";
  };

  useEffect(() => {
    document.addEventListener("mousemove", mouseMoveEvent);

    return () => {
      document.removeEventListener("mousemove", mouseMoveEvent);
    };
  }, []);

  return (
    <div className="cursor" ref={dot}>
      <div className="pointer"></div>
    </div>
  );
};

export default Cursor;
