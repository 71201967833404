import React from "react";
import Cursor from "../components/Cursor";
import Navigation from "../components/Navigation";

const Home = () => {
  return (
    <div className="back">
      <div className="back_gradient">
        <Cursor />
        <Navigation />
        <div className="container_accueil">
          <div className="container_a_propo">
            <h1>Bonjour</h1>
            <p className="a_propo">
              Après avoir eu diverses expériences professionnelles, j’ai décidé
              de m’orienter vers un métier qui pouvait me passionner, je me suis
              donc dirigé vers les métiers de l’informatique et plus précisément
              dans le développement. J'ai commencé à m’auto-former plusieurs
              mois afin de pouvoir accéder à la formation SIMPLON.
            </p>
          </div>
          <div className="container_profil">
            <div className="profil">
              <h1 className="nom">Nans THOMAS</h1>
              <ul className="list_profil">
                <li className="info">
                  <a
                    href="https://gitlab.com/NansT"
                    target="blank"
                    className="gitlab"
                  >
                    <i className="fa fa-user"></i> Gitlab.com
                  </a>
                </li>

                <li className="info">
                  <div>
                    <i className="fa fa-phone"></i> +33 6 14 96 68 46
                  </div>
                </li>

                <li className="info">
                  <div>
                    <i className="fa fa-at"></i> nans07100@gmail.com
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
