import React from "react";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  return (
    <nav className="navigation">
      <ul className="">
        <NavLink to="/" className={(nav) => (nav.isActive ? "nav-active" : "")}>
          <div className="linknav">
            <li className="navmobil fas fa-home"></li>
            <li className="navlink">Accueil</li>
          </div>
        </NavLink>
        <NavLink
          to="/Competence"
          className={(nav) => (nav.isActive ? "nav-active" : "")}
        >
          <div className="linknav">
            <li className="navmobil fas fa-mountain"></li>
            <li className="navlink">Competence</li>
          </div>
        </NavLink>
        <NavLink
          to="/Portefolio"
          className={(nav) => (nav.isActive ? "nav-active" : "")}
        >
          <div className="linknav">
            <li className="navmobil fas fa-briefcase"></li>
            <li className="navlink">Portfolio</li>
          </div>
        </NavLink>
        <NavLink
          to="/Contact"
          className={(nav) => (nav.isActive ? "nav-active" : "")}
        >
          <div className="linknav">
            <li className="navmobil fas fa-address-book"></li>
            <li className="navlink">Contacte</li>
          </div>
        </NavLink>
      </ul>
    </nav>
  );
};

export default Navigation;
