import React from "react";
import Cursor from "../components/Cursor";
import Navigation from "../components/Navigation";
import ProjetListe from "../components/portfolio/ProjetListe";

const Portefolio = () => {
  return (
    <div className="back">
      <div className="back_gradient">
        <Cursor />
        <Navigation />
        <div className="container_portfolio">
          <ProjetListe />
        </div>
      </div>
    </div>
  );
};

export default Portefolio;
