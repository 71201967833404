import React from "react";
import ContactUs from "../components/contact/ContactUs";
import Cursor from "../components/Cursor";
import Navigation from "../components/Navigation";

const Contact = () => {
  return (
    <div className="back">
      <div className="back_gradient">
        <Cursor />
        <Navigation />
        <div className="container_contact">
          <ContactUs />
        </div>
      </div>
    </div>
  );
};

export default Contact;
