import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_k4rt6id",
        "template_s5vnklr",
        form.current,
        "RAez1MOnQVZsFeZAq"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="contact">
      <h2>Contacter Moi</h2>
      <div className="container_form">
        <form className="form" ref={form} onSubmit={sendEmail}>
          <div className="message">
            <div className="nom_prenom">
              <div className="input">
                <input type="text" name="name" placeholder="Nom" required />
              </div>
              <div className="input">
                <input
                  type="text"
                  name="prenom"
                  placeholder="Prenom"
                  required
                />
              </div>
            </div>
            <div className="input">
              <input type="email" name="email" placeholder="E-mail" required />
            </div>
            <div className="input">
              <textarea name="message" placeholder="Message" />
            </div>
          </div>
          <input className="send" type="submit" value="Envoyer" />
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
