import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Competence from "./page/Competence";
import Contact from "./page/Contact";
import Home from "./page/Home";
import Portefolio from "./page/Portefolio";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Competence" element={<Competence />} />
        <Route path="/Portefolio" element={<Portefolio />} />
        {/* path="*" renvoi les url non éxistent sur la page Home */}
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
