import React from "react";

const Langages = () => {
  return (
    <div className="container_langages">
      <h1>Langages</h1>
      <div className="container_box_langage">
        <div className="box_langages">
          <div className="box_langage">
            <i className="langage fab fa-html5"></i>
          </div>
          <div className="box_langage">
            <i className="langage fab fa-css3-alt"></i>
          </div>
          <div className="box_langage">
            <i className="langage fab fa-js"></i>
          </div>
          <div className="box_langage">
            <i className="langage fab fa-react"></i>
          </div>
          <div className="box_langage">
            <i className="langage fab fa-php"></i>
          </div>
          <div className="box_langage">
            <i className="langage fab fa-symfony"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Langages;
