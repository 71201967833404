import React, { Component } from "react";
import { portfolioData } from "../../data/portfolioData";
import Projet from "./Projet";

class ProjetListe extends Component {
  state = {
    projet: portfolioData,
    radios: [
      { id: 1, value: "javascript", icon: "fab fa-js" },
      { id: 2, value: "css", icon: "fab fa-css3-alt" },
      { id: 3, value: "php", icon: "fab fa-php" },
      { id: 4, value: "symfony", icon: "fab fa-symfony" },
    ],
    selectedRadio: "javascript",
  };

  handleRadio = (event) => {
    let radio = event.target.value;
    this.setState({ selectedRadio: radio });
  };

  render() {
    let { projet, radios, selectedRadio } = this.state;
    return (
      <div className="portfolio_content">
        <ul className="radiosDisplay">
          {radios.map((radio) => {
            return (
              <li className="radios liste" key={radio.id}>
                <input
                  type="radio"
                  name="radio"
                  checked={radio.value === selectedRadio}
                  value={radio.value}
                  id={radio.value}
                  onChange={this.handleRadio}
                />
                <label htmlFor={radio.value} className={radio.icon}></label>
              </li>
            );
          })}
        </ul>
        <div className="projets">
          {projet
            .filter((item) => item.languages.includes(selectedRadio))
            .map((item) => {
              return <Projet key={item.id} item={item} />;
            })}
        </div>
      </div>
    );
  }
}

export default ProjetListe;
