import React, { Component } from "react";

class Projet extends Component {
  render() {
    let { name, source, picture } = this.props.item;

    return (
      <a href={source} target="_blank" className="projet">
        <h2 className="name_projet">{name}</h2>
        <img className="img_projet" src={picture} alt="" />
      </a>
    );
  }
}

export default Projet;
