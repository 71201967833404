import React from "react";

const Diplome = () => {
  return (
    <div className="container_diplome">
      <h1>Diplomes et Formations</h1>
      <div className="formation-diplome">
        <p className="date">2021-2022</p>
        <div className="formation formation-1">
          <div className="information">
            <h2 className="">Ecole Numérique Ardéchoise</h2>
            <p className="">Le Cheylard (07)</p>
            <a href="https://en-07.fr/" className="lien">
              Lien
            </a>
          </div>
          <div className="diplome">
            <h3 className="nom-diplome">
              TITRE PROFESSIONNEL DÉVELOPPEUR WEB ET WEB MOBILE NIVEAU BAC+2
            </h3>
            <p>Formation de 9 mois</p>
          </div>
        </div>

        <p className="date">2016-2018</p>
        <div className="formation formation-2">
          <div className="information">
            <h2 className="">Lycée Professionnel E. Legrand</h2>
            <p className=""> Le Coteau (42)</p>
            <a
              href="https://etienne-legrand.ent.auvergnerhonealpes.fr/"
              className="lien"
            >
              Lien
            </a>
          </div>
          <div className="diplome">
            <h3 className="nom-diplome">
              BAC PRO OUVRAGE DU BÂTIMENT, OPTION MÉTALLERIE
            </h3>
          </div>
        </div>

        <p className="date">2014-2016</p>
        <div className="formation formation-3">
          <div className="information">
            <h2 className="">École de Production AFEP</h2>
            <p className=""> Saint Étienne (42)</p>
            <a href="https://www.afep.org/" className="lien">
              Lien
            </a>
          </div>
          <div className="diplome">
            <h3 className="nom-diplome">CAP MÉTALLIER SERRURIER</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Diplome;
